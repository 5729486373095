<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";

const showDropdown = ref(false);
const dropdownRef = ref(null);
const buttonRef = ref(null);

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

const closeDropdown = (event) => {
  if (
    dropdownRef.value &&
    !dropdownRef.value.contains(event.target) &&
    buttonRef.value &&
    !buttonRef.value.contains(event.target)
  ) {
    showDropdown.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", closeDropdown);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", closeDropdown);
});
</script>

<template>
  <div class="relative" ref="buttonRef">
    <div
      class="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full text-black font-bold"
    >
      <img
        class="w-6 h-8 rounded-full"
        src="assets/images/user.png"
        alt="user photo"
      />
    </div>
  </div>
</template>
